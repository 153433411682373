<template>
  <div>
  <div v-if="isMobile" class="app-container">
    <div class="body">
      <div class="announcement" style="border-bottom: none">
        <div class="vertical"></div>
        <h3 class="title">{{data.title}}</h3>
      </div>
      <div class="time">{{data.createTime}}</div>
      <div class="ql-editor" style="margin-top: 20px" v-html="data.value"></div>
    </div>
  </div>
  <div v-else>
      <div class="mannouncement" style="border-bottom: none">
          
          <h3 class="mtitle">{{ data.title }}</h3>
        </div>
        <div class="mtime">{{ data.createTime }}</div>
        <div
          class="ql-editor"
          style="margin-top: 2.6667vw"
          v-html="data.value"
        ></div>
    </div>
  </div>
</template>

<script>
import { getNewsDetails } from "../http/api/api";
export default {
  data() {
    return {
      data: {},
      isMobile: false,
    };
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  mounted() {
    const Id = this.$route.query.id;
    this.getNewsDetails(Id);
  },
  methods: {
    getNewsDetails(id) {
      getNewsDetails(id).then((res) => {
        this.data = res.data
      });
    },
     checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #fff;
  .announcement {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    border-bottom: 1px solid #ececec;
    .vertical {
      width: 4px;
      height: 24px;
      background: #d8322d;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      margin-right: 4px;
    }
    .title {
      font-size: 20px;
      margin: 0;
    }
  }
  .time {
    font-size: 16px;
    margin-top: 10px;
    margin-left: 6px;
    color: #dcdcdc;
  }
}

.mannouncement {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    margin-left: 5.3333vw;
    margin-top: 5.3333vw;
    .mvertical {
      width: 1.0667vw;
      height: 6vw;
      background: #d8322d;
      border-radius: 50px;
      opacity: 1;
      margin-right: 1.0667vw;
      
    }
    .mtitle {
      font-size: 4.2667vw;
      margin: 0;
    }
  }
  .mtime {
    font-size: 3.7333vw;
    margin-top: 2.6667vw;
    margin-left: 5.3333vw;
    color: #dcdcdc;
  }
</style>