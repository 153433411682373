<template>
  <div>
    <div v-if="isMobile" class="home">
      <div style="margin-bottom: 50px; width: 100%; height: 500px">
        <img style="width: 100%; height: 500px" :src="headView" alt="" />
      </div>
      <div class="offlinePromotion">
        <div class="offlinePromotion-title">
          <div class="underline"></div>
          <div class="offlinePromotion-title-name">核心业务</div>
        </div>
        <div>
          <div class="offlinePromotion-content">
            <div class="offlinePromotion-content-title">
              <div class="offlinePromotion-content-title-name">随亿兑</div>
            </div>
            <div class="offlinePromotion-content-img">
              <div class="offlinePromotion-content-img-l">
                <img :src="equityConversion" alt="" />
              </div>
              <!-- <div class="offlinePromotion-content-img-r">
              <div
                :class="{
                  'offlinePromotion-content-img-r-content-concrete': true,
                  active: activeIndex == index,
                }"
                v-for="(item, index) in offlinePromotionArea"
                :key="index"
                @click="selectedArea(item, index)"
              >
                {{ item.local }}
              </div>
            </div> -->
            </div>
          </div>
          <div
            style="border-top: 1px solid #999; margin-top: 60px"
            class="offlinePromotion-content"
          >
            <div class="offlinePromotion-content-title">
              <div class="offlinePromotion-content-title-name">丽璟商城</div>
            </div>
            <div class="offlinePromotion-content-img">
              <div class="offlinePromotion-content-img-l">
                <img :src="lijingMall" alt="" />
              </div>
              <!-- <div class="offlinePromotion-content-img-r">
              <div
                :class="{
                  'offlinePromotion-content-img-r-content-concrete': true,
                  active: activeIndex == index,
                }"
                v-for="(item, index) in offlinePromotionArea"
                :key="index"
                @click="selectedArea(item, index)"
              >
                {{ item.local }}
              </div>
            </div> -->
            </div>
          </div>
          <div
            style="border-top: 1px solid #999; margin-top: 60px"
            class="offlinePromotion-content"
          >
            <div class="offlinePromotion-content-title">
              <div class="offlinePromotion-content-title-name">众估宝</div>
            </div>
            <div class="offlinePromotion-content-img">
              <div class="offlinePromotion-content-img-l">
                <img :src="zgbImg" alt="" />
              </div>
              <!-- <div class="offlinePromotion-content-img-r">
              <div
                :class="{
                  'offlinePromotion-content-img-r-content-concrete': true,
                  active: activeIndex == index,
                }"
                v-for="(item, index) in offlinePromotionArea"
                :key="index"
                @click="selectedArea(item, index)"
              >
                {{ item.local }}
              </div>
            </div> -->
            </div>
          </div>
          <div class="onlinePromotion-content">
            <div class="onlinePromotion-content-title">
              <div class="onlinePromotion-content-title-name">线上推广</div>
            </div>
            <div class="onlinePromotion-content-img">
              <div class="onlinePromotion-content-img-l">
                <img :src="onlinePromotionPicture" alt="" />
                <div class="onlinePromotion-introduce">
                  {{ onlinePromotionInformation }}
                </div>
              </div>
              <div class="onlinePromotion-content-img-r">
                <div
                  :class="{
                    'onlinePromotion-content-img-concrete': true,
                    active: activeIndex2 == index,
                  }"
                  v-for="(item, index) of onlineProjectName"
                  :key="index"
                  @click="selectOnlineItems(item, index)"
                >
                  {{ item.local }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="caseIntroduction">
        <div class="caseIntroduction-title">
          <div class="underline"></div>
          <div class="caseIntroduction-title-name">案例介绍</div>
        </div>
        <div class="caseIntroduction-content">
          <div class="caseIntroduction-content-3">
            <div class="caseIntroduction-content-3-l">
              <div class="caseIntroduction-content-3-l">
                <div class="caseIntroduction-content-3-l-title">众估宝平台</div>
                <div class="caseIntroduction-content-3-l-img">
                  <img src="../assets/3-1.png" alt="" />
                </div>
              </div>
            </div>
            <div class="caseIntroduction-content-3-r">
              <div class="caseIntroduction-content-3-r">
                <div class="caseIntroduction-content-3-r-title">
                  微信端商城<br />
                  丽璟商贸
                </div>
                <div class="caseIntroduction-content-3-r-img">
                  <img src="../assets/3-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="caseIntroduction-content-2">
            <div class="caseIntroduction-content-2-r">
              <div class="caseIntroduction-content-2-r-title">
                微信端小程序<br />
                随亿兑
              </div>
              <div class="caseIntroduction-content-2-r-img">
                <img src="../assets/2-2.png" alt="" />
              </div>
            </div>
            <div class="caseIntroduction-content-2-l">
              <div class="caseIntroduction-content-2-l-title">
                微信端小程序<br />
                安全教育
              </div>
              <div class="caseIntroduction-content-2-l-img">
                <img src="../assets/2-1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="caseIntroduction-content-1">
            <div class="caseIntroduction-content-1-l">
              <div class="caseIntroduction-content-1-l-title">
                微信端小程序<br />
                推广文案资料库
              </div>
              <div class="caseIntroduction-content-1-l-img">
                <img src="../assets/1-1.png" alt="" />
              </div>
            </div>
            <div class="caseIntroduction-content-1-r">
              <div class="caseIntroduction-content-1-r-title">
                微信端小游戏<br />
                章鱼模玩
              </div>
              <div class="caseIntroduction-content-1-r-img">
                <img src="../assets/1-2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div style="width: 100vw; height: 53.3333vw">
        <img style="width: 100%; height: 100%" :src="headView" alt="" />
      </div>
      <div class="mCoreBusiness">
        <div class="mCoreBusiness-title">核心业务</div>
        <div class="mCoreBusiness-1">
          <div class="mCoreBusiness-1-title">随亿兑</div>
          <img
            style="width: 85vw; height: 45vw; margin-top: 1.3333vw"
            :src="equityConversion"
            alt=""
          />
        </div>
        <div class="mCoreBusiness-1">
          <div class="mCoreBusiness-1-title">丽璟商城</div>
          <img
            style="width: 85vw; height: 45vw; margin-top: 1.3333vw"
            :src="lijingMall"
            alt=""
          />
        </div>
        <div class="mCoreBusiness-1">
          <div class="mCoreBusiness-1-title">众估保</div>
          <img
            style="width: 85vw; height: 45vw; margin-top: 1.3333vw"
            :src="zgbImg"
            alt=""
          />
        </div>
      </div>
      <div class="mcaseIntroduction">
        <div class="mcaseIntroduction-title">案例介绍</div>
        <div class="mcaseIntroduction-content">
          <img class="bgcImg" src="../assets/5.png" alt="" />
          <div class="mcaseIntroduction-content-title">众估宝平台</div>
          <img
            style="
              width: 55%;
              height: 75%;
              position: absolute;
              bottom: 0;
              right: 2vw;
            "
            src="../assets/3-1.png"
            alt=""
          />
        </div>
        <div class="mcaseIntroduction-content">
          <img
            style="width: 104vw"
            class="bgcImg"
            src="../assets/6.png"
            alt=""
          />
          <div class="mcaseIntroduction-content-title">
            微信端商城<br />
            丽璟商贸
          </div>
          <img
            style="
              width: 50%;
              height: 90%;
              position: absolute;
              bottom: 1.3333vw;
              right: 2.6667vw;
            "
            src="../assets/3-2.png"
            alt=""
          />
        </div>
        <div class="mcaseIntroduction-content">
          <img class="bgcImg" src="../assets/4.png" alt="" />
          <div class="mcaseIntroduction-content-title">
            微信端小程序<br />
            随亿兑
          </div>
          <img
            style="
              width: 22%;
              height: 85%;
              position: absolute;
              bottom: 1.3333vw;
              right: 15vw;
            "
            src="../assets/2-2.png"
            alt=""
          />
        </div>
        <div class="mcaseIntroduction-content">
          <img
            style="width: 100vw"
            class="bgcImg"
            src="../assets/3.png"
            alt=""
          />
          <div class="mcaseIntroduction-content-title">
            微信端小程序<br />
            安全教育
          </div>
          <img
            style="
              width: 30%;
              height: 80%;
              position: absolute;
              bottom: 1.3333vw;
              right: 10vw;
            "
            src="../assets/2-1.png"
            alt=""
          />
        </div>
        <div class="mcaseIntroduction-content">
          <img
            style="width: 104vw"
            class="bgcImg"
            src="../assets/6.png"
            alt=""
          />
          <div class="mcaseIntroduction-content-title">
            微信端小程序<br />
            推广文案资料库
          </div>
          <img
            style="
              width: 55%;
              height: 65%;
              position: absolute;
              bottom: 1.3333vw;
              right: 1vw;
            "
            src="../assets/1-1.png"
            alt=""
          />
        </div>
        <div class="mcaseIntroduction-content">
          <img
            style="width: 102vw"
            class="bgcImg"
            src="../assets/2.png"
            alt=""
          />
          <div class="mcaseIntroduction-content-title">
            微信端小游戏<br />
            章鱼模玩
          </div>
          <img
            style="
              width: 50%;
              height: 85%;
              position: absolute;
              bottom: 1.3333vw;
              right: 0;
            "
            src="../assets/1-2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import {
  getHeadView,
  getOfflinePromotion,
  getRegionalContent,
  getOnlineProjectName,
  getOnlineProjectContent,
} from "../http/api/api";
import serverConfig from "../http/config/index";
export default {
  name: "projectBusiness",
  data() {
    return {
      headView: "",
      options: [
        {
          value: "选项1",
          label: "山东",
        },
        {
          value: "选项2",
          label: "北京",
        },
        {
          value: "选项3",
          label: "浙江",
        },
        {
          value: "选项4",
          label: "上海",
        },
        {
          value: "选项5",
          label: "苏州",
        },
      ],
      value: "浙江",
      offlinePromotionArea: [],
      onlineProjectName: [],
      offlinePicture: null,
      activeIndex: 0,
      activeIndex2: 0,
      onlinePromotionPicture: null,
      onlinePromotionInformation: null,
      equityConversion: null,
      lijingMall: null,
      zgbImg: null,
      isMobile: false,
    };
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
    this.getHeadView();
    this.getOfflinePromotion();
    this.getOnlineProjectName();
    this.getAdvertisingMap();
  },
  methods: {
    // top() {
    //   this.contentStyle.transform = `translateY(${-90}px)`;
    // },
    // down() {
    //   this.contentStyle.transform = `translateY(${90}px)`;
    // },
    to() {
      this.$refs["aboutUs"].scrollIntoView(true);
    },
    selectOnlineItems(item, index) {
      this.activeIndex2 = index;
      this.getRegionalContent2(item.local);
    },
    selectedArea(item, index) {
      this.activeIndex = index;
      this.getRegionalContent(item.local);
    },
    getOfflinePromotion() {
      getOfflinePromotion().then((res) => {
        this.offlinePromotionArea = res.data;
        if (res.data.length >= 1) {
          this.getRegionalContent(res.data[0].local);
        }
      });
    },
    getOnlineProjectName() {
      getOnlineProjectName().then((res) => {
        this.onlineProjectName = res.data;
        if (res.data.length >= 1) {
          this.getRegionalContent2(res.data[0].local);
        }
      });
    },
    getRegionalContent2(local) {
      getOnlineProjectContent(local).then((res) => {
        this.onlinePromotionPicture = serverConfig.baseURL + res.data[0].imgUrl;
        this.onlinePromotionInformation = res.data[0].commits;
      });
    },
    getRegionalContent(local) {
      getRegionalContent(local).then((res) => {
        this.offlinePicture = serverConfig.baseURL + res.data[0].imgUrl;
      });
    },
    toWheelSeedingPicture() {
      this.$refs["wheelSeedingPicture"].scrollIntoView(true);
    },
    getAdvertisingMap() {
      getHeadView(25).then((res) => {
        res.data.forEach((item) => {
          this.equityConversion = serverConfig.baseURL + item.imgUrl;
        });
      });
      getHeadView(26).then((res) => {
        res.data.forEach((item) => {
          this.lijingMall = serverConfig.baseURL + item.imgUrl;
        });
      });
      getHeadView(27).then((res) => {
        res.data.forEach((item) => {
          this.zgbImg = serverConfig.baseURL + item.imgUrl;
        });
      });
    },
    getHeadView() {
      getHeadView(13).then((res) => {
        res.data.forEach((item) => {
          this.headView = serverConfig.baseURL + item.imgUrl;
        });
      });
    },

    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offlinePromotion {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
  .offlinePromotion-title {
    .underline {
      width: 180px;
      height: 4px;
      background: #dc1010;
      // margin-top: 6px;
      // margin-bottom: 25px;
    }
    .offlinePromotion-title-name {
      font-size: 36px;
      font-weight: 500;
      color: #dc1010;
      margin-top: 20px;
    }
  }
  .offlinePromotion-content {
    border-top: 1px solid #999;
    width: 1000px;
    padding-top: 10px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    .offlinePromotion-content-title {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .offlinePromotion-content-title-name {
        font-size: 25px;
        font-weight: 400;
        color: #d21b1a;
      }
    }
    .offlinePromotion-content-img {
      width: 100%;
      height: 500px;
      margin-top: 20px;
      display: flex;
      position: relative;
      .offlinePromotion-content-img-l {
        width: 100%;
        height: 500px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .offlinePromotion-content-img-r {
        width: 20%;
        height: 500px;
        background: #393a3a;
        opacity: 0.7;
        box-sizing: border-box;
        overflow-y: auto;
        position: absolute;
        right: 0;
        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          width: 6px;
          background: rgba(#101f1c, 0.1);
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
        // 滚动条的轨道（里面装有Thumb）
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.5);
          background-clip: padding-box;
          min-height: 28px;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
          transition: background-color 0.3s;
          cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(144, 147, 153, 0.3);
        }
        .offlinePromotion-content-img-r-content-concrete {
          height: 100px;
          width: 100%;
          font-size: 24px;
          border-bottom: 1px solid #a3a3a3;
          box-sizing: border-box;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
  .onlinePromotion-content {
    border-top: 1px solid #999;
    width: 1000px;
    padding-top: 10px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin-top: 60px;
    .onlinePromotion-content-title {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .onlinePromotion-content-title-name {
        font-size: 25px;
        font-weight: 400;
        color: #d21b1a;
      }
    }
    .onlinePromotion-content-img {
      width: 100%;
      height: 500px;
      margin-top: 20px;
      display: flex;
      position: relative;
      .onlinePromotion-content-img-l {
        width: 100%;
        height: 500px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .onlinePromotion-introduce {
          width: 70%;
          height: 70%;
          position: absolute;
          top: 100px;
          left: 45px;
          font-size: 20px;
          color: #ffffff;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          &::-webkit-scrollbar-track {
            width: 6px;
            background: rgba(#101f1c, 0.1);
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
          }
          // 滚动条的轨道（里面装有Thumb）
          &::-webkit-scrollbar-thumb {
            background-color: rgba(144, 147, 153, 0.5);
            background-clip: padding-box;
            min-height: 28px;
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
            transition: background-color 0.3s;
            cursor: pointer;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(144, 147, 153, 0.3);
          }
        }
      }
      .onlinePromotion-content-img-r {
        width: 20%;
        height: 500px;
        background: #393a3a;
        opacity: 0.7;
        box-sizing: border-box;
        overflow-y: auto;
        position: absolute;
        right: 0;
        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          width: 6px;
          background: rgba(#101f1c, 0.1);
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
        // 滚动条的轨道（里面装有Thumb）
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.5);
          background-clip: padding-box;
          min-height: 28px;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
          transition: background-color 0.3s;
          cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(144, 147, 153, 0.3);
        }
        .onlinePromotion-content-img-concrete {
          height: 100px;
          width: 100%;
          font-size: 22px;
          border-bottom: 1px solid #a3a3a3;
          box-sizing: border-box;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}

.caseIntroduction {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .caseIntroduction-title {
    .underline {
      width: 180px;
      height: 4px;
      background: #dc1010;
    }
    .caseIntroduction-title-name {
      font-size: 36px;
      font-weight: 500;
      color: #dc1010;
      margin-top: 20px;
    }
  }
  .caseIntroduction-content {
    width: 1000px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 70px;
    margin-top: -5px;
    .caseIntroduction-content-1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .caseIntroduction-content-1-l {
        width: 550px;
        height: 260px;
        background: url(../assets/1.png);
        border-radius: 20px;
        position: relative;
        transition: all 0.5s;
        .caseIntroduction-content-1-l-title {
          position: absolute;
          font-size: 20px;
          font-weight: bold;
          color: #323333;
          line-height: 30px;
          left: 20px;
          top: 40px;
        }
        .caseIntroduction-content-1-l-img {
          position: absolute;
          right: 0;
          top: 30px;
        }
      }
      .caseIntroduction-content-1-r {
        width: 430px;
        height: 260px;
        background: url(../assets/2.png);
        border-radius: 20px;
        position: relative;
        transition: all 0.5s;
        .caseIntroduction-content-1-r-title {
          position: absolute;
          font-size: 20px;
          font-weight: bold;
          color: #323333;
          line-height: 30px;
          left: 30px;
          top: 40px;
        }
        .caseIntroduction-content-1-r-img {
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
      .caseIntroduction-content-1-l:hover {
        transform: scale(1.05);
      }
      .caseIntroduction-content-1-r:hover {
        transform: scale(1.05);
      }
    }

    .caseIntroduction-content-2 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .caseIntroduction-content-2-l {
        width: 470px;
        height: 260px;
        background: url(../assets/3.png);
        border-radius: 20px;
        position: relative;
        transition: all 0.5s;
        .caseIntroduction-content-2-l-title {
          position: absolute;
          font-size: 20px;
          font-weight: bold;
          color: #323333;
          line-height: 30px;
          left: 40px;
          top: 40px;
        }
        .caseIntroduction-content-2-l-img {
          position: absolute;
          right: 60px;
          top: 30px;
        }
      }
      .caseIntroduction-content-2-l:hover {
        transform: scale(1.05);
      }
      .caseIntroduction-content-2-r {
        width: 510px;
        height: 260px;
        background: url(../assets/4.png);
        border-radius: 20px;
        position: relative;
        transition: all 0.5s;
        .caseIntroduction-content-2-r-title {
          position: absolute;
          font-size: 20px;
          font-weight: bold;
          color: #323333;
          line-height: 30px;
          left: 50px;
          top: 40px;
        }
        .caseIntroduction-content-2-r-img {
          position: absolute;
          right: 100px;
          top: 20px;
        }
      }
      .caseIntroduction-content-2-r:hover {
        transform: scale(1.05);
      }
    }
    .caseIntroduction-content-3 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .caseIntroduction-content-3-l {
        width: 550px;
        height: 260px;
        background: url(../assets/5.png);
        border-radius: 20px;
        position: relative;
        transition: all 0.5s;
        .caseIntroduction-content-3-l-title {
          position: absolute;
          font-size: 20px;
          font-weight: bold;
          color: #323333;
          line-height: 30px;
          left: 50px;
          top: 40px;
        }
        .caseIntroduction-content-3-l-img {
          position: absolute;
          right: 20px;
          bottom: 4px;
        }
      }
      .caseIntroduction-content-3-l:hover {
        transform: scale(1.03);
      }
      .caseIntroduction-content-3-r {
        width: 430px;
        height: 260px;
        background: url(../assets/6.png);
        border-radius: 20px;
        position: relative;
        transition: all 0.5s;
        .caseIntroduction-content-3-r-title {
          position: absolute;
          font-size: 20px;
          font-weight: bold;
          color: #323333;
          line-height: 30px;
          left: 50px;
          top: 40px;
        }
        .caseIntroduction-content-3-r-img {
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
      }
      .caseIntroduction-content-3-r:hover {
        transform: scale(1.03);
      }
    }
  }
}
.active {
  color: #d3231c !important;
}
::v-deep .el-input__wrapper {
  height: 35px;
}

::v-deep .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: 0 0 0 1px #dc1010 !important;
}

::v-deep .el-select .el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #dc1010 !important;
}
</style>

<style lang="scss" scoped>
.mCoreBusiness {
  margin-top: 5.3333vw;
  .mCoreBusiness-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
  .mCoreBusiness-1 {
    width: 85vw;
    margin: 0 auto;
    .mCoreBusiness-1-title {
      font-size: 4.8vw;
      color: #dc1010;
    }
  }
}
.mcaseIntroduction {
  margin-top: 5.3333vw;
  .mcaseIntroduction-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
  .mcaseIntroduction-content {
    width: 85vw;
    margin: 0 auto;
    height: 40vw;
    overflow: hidden;
    position: relative;
    margin-bottom: 2.6667vw;
    .bgcImg {
      width: 98vw;
      height: 70vw;
      position: absolute;
      z-index: -99;
    }
    .mcaseIntroduction-content-title {
      font-size: 4.2667vw;
      font-weight: bold;
      color: #323333;
      position: absolute;
      left: 5.3333vw;
      top: 5.3333vw;
    }
  }
}
</style>