import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/permission' // permission control
import "quill/dist/quill.core.css"
import vant from 'vant';
import 'vant/lib/index.css';
// 引入Swiper的样式文件
// import 'swiper/css/swiper.css';

// Vue.use(VueAwesomeSwiper);



createApp(App).use(store).use(router).use(vant).use(ElementPlus).mount('#app')