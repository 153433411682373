import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Contact from '../views/Contact.vue'
import aboutUs from '../views/aboutUs.vue'
import newsTrends from '../views/newsTrends.vue'
import projectBusiness from '../views/projectBusiness.vue'
import advertisingCenter from '../views/advertisingCenter.vue'
import joinUs from '../views/joinUs.vue'
import contactUs from '../views/contactUs.vue'
import announcementDetails from '../views/announcementDetails.vue'
import advertisingCenterSpecificContent from '../views/advertisingCenterSpecificContent.vue'
const routes = [{
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { title: '首页', index: 0, affix: true }
    },
    {
        path: '/us',
        component: aboutUs,
        meta: { title: '关于我们', index: 1, affix: true }
    },
    {
        path: '/newsTrends',
        name: 'newsTrends',
        component: newsTrends,

        meta: { title: '新闻动态', index: 2, affix: true }
    },
    {
        path: '/projectBusiness',
        name: 'projectBusiness',
        component: projectBusiness,
        meta: { title: '项目业务', index: 3, affix: true }
    },
    {
        path: '/advertisingCenter',
        name: 'advertisingCenter',
        component: advertisingCenter,
        meta: { title: '广告中心', index: 4, affix: true }
    },
    {
        path: '/joinUs',
        name: 'joinUs',
        component: joinUs,
        meta: { title: '加入我们', index: 5, affix: true }
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: contactUs,
        meta: { title: '加入我们', index: 6, affix: true }
    },
    {
        path: '/announcementDetails',
        name: 'announcementDetails',
        component: announcementDetails,
        meta: { index: 2, affix: true }
    },
    {
        path: '/advertisingCenterSpecificContent',
        name: 'advertisingCenterSpecificContent',
        component: advertisingCenterSpecificContent,
        meta: { index: 4, affix: true }
    },
]


const router = createRouter({
    history: createWebHashHistory(),
    routes,
})


router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0); // 将浏览器滚动到顶部
    next();
});

export default router