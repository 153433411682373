<template>
  <div>
    <div v-if="isMobile" class="news">
      <img
        style="width: 100%; height: 500px"
        class="topimg"
        :src="headView"
        alt=""
      />
      <div class="contact">
        <div class="contact-title">
          <div class="underline"></div>
          <div class="contact-title-name">广告</div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <div class="companyNews">
            <div
              class="companyNews-content"
              v-for="(item, index) of IndustryNewsContent"
              :key="index"
              @click="goSpecificNews(item)"
            >
              <div class="companyNews-content-img">
                <img :src="item.img" alt="" />
              </div>
              <div class="companyNews-content-intro">
                <div class="companyNews-content-intro-time">
                  {{ item.createTime }}
                </div>
                <div class="companyNews-content-intro-title">
                  {{ item.title }}
                </div>
                <div class="companyNews-content-intro-content">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
            :page-size="5"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <div style="width: 100vw; height: 53.3333vw">
        <img style="width: 100%; height: 100%" :src="headView" alt="" />
      </div>
      <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div
              class="mcontent"
              v-for="(item, index) of IndustryNewsContent"
              :key="index"
              @click="goSpecificNews(item)"
            >
              <div class="mcontent-img">
                <img :src="item.img" alt="" />
              </div>
              <div class="mcontent-intro">
                <div class="mcontent-intro-time">
                  {{ item.createTime }}
                </div>
                <div class="mcontent-intro-title">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            layout="pager"
            :page-size="5"
            :total="total"
          >
          </el-pagination>
          </div>
    </div>
  </div>
</template>

<script>
import { MoreFilled } from "@element-plus/icons-vue";
import { getHeadView, getAdvertisingCenter } from "../http/api/api";
import serverConfig from "../http/config/index";
export default {
  data() {
    return {
      headView: "",
      data: {
        pageNum: 1,
        pageSize: 5,
      },
      total: null,
      IndustryNewsContent: [],
      isMobile: false,
      activities: [
        {
          content: "Custom icon",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          icon: MoreFilled,
        },
        {
          content: "Custom color",
          timestamp: "2018-04-03 20:46",
          color: "#0bbd87",
        },
        {
          content: "Custom size",
          timestamp: "2018-04-03 20:46",
          size: "large",
        },
        {
          content: "Custom hollow",
          timestamp: "2018-04-03 20:46",
          type: "primary",
          hollow: true,
        },
        {
          content: "Default node",
          timestamp: "2018-04-03 20:46",
        },
      ],
    };
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  mounted() {
    this.getHeadView();
    this.getIndustryNew();
  },
  methods: {
    handleSizeChange(val) {
      this.data.pageNum = val;
      this.getIndustryNew();
      // 在这里根据新的 pageSize 请求数据
    },
    handleCurrentChange(val) {
      this.data.pageNum = val;
      this.getIndustryNew();
      // 在这里根据新的 currentPage 请求数据
    },
    getIndustryNew() {
      let data = this.data;
      getAdvertisingCenter(data).then((res) => {
        res.rows.forEach((item) => {
          item.img = serverConfig.baseURL + item.img;
        });
        this.IndustryNewsContent = res.rows;
        this.total = res.total;
      });
    },
    getHeadView() {
      getHeadView(14).then((res) => {
        res.data.forEach((item) => {
          this.headView = serverConfig.baseURL + item.imgUrl;
        });
      });
    },
    goSpecificNews(item) {
      this.$router.push({
        path: "/advertisingCenterSpecificContent",
        query: { id: item.id },
      });
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  display: flex;

  flex-direction: column;
  align-items: center;
}
.topimg {
  width: 100%;
  height: 400px;
}
.search {
  width: 60%;
  height: 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.contact {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 100px;
  margin-top: 40px;
  display: flex;
  .contact-title {
    .underline {
      width: 180px;
      height: 4px;
      background: #dc1010;
      // margin-top: 6px;
      // margin-bottom: 25px;
    }
    .contact-title-name {
      font-size: 36px;
      font-weight: 500;
      color: #dc1010;
      margin-top: 20px;
    }
  }
  .companyNews {
    width: 1000px;
    height: 1000px;
    box-sizing: border-box;
    margin-left: 20px;

    .companyNews-content {
      width: 100%;
      height: 170px;
      box-sizing: border-box;
      display: flex;
      margin-bottom: 30px;
      transition: all 0.5s;
      .companyNews-content-img {
        width: 300px;
        height: 170px;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .companyNews-content-intro {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 18px;
        box-sizing: border-box;
        .companyNews-content-intro-time {
          font-size: 16px;
          font-weight: 500;
          color: #999999;
        }
        .companyNews-content-intro-title {
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          width: 468px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .companyNews-content-intro-content {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          width: 640px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }
    .companyNews-content:hover {
      background: #eeeeee;
      cursor: pointer;
      border-bottom: 4px solid #e0100d;
      transform: scale(1.03);
    }
  }
}

// ::v-deep .el-tabs__content {
//     overflow: visible;
//   }
::v-deep .el-tabs__item {
  font-size: 25px;
  color: #d6d6d6;
}
::v-deep .el-tabs__item.is-active {
  color: #dc1010;
}
::v-deep .el-icon-arrow-left {
  color: white;
}
::v-deep .el-icon-arrow-right {
  color: white;
}
::v-deep .el-tabs__active-bar {
  background-color: #dc1010;
}
::v-deep .el-tabs__item:hover {
  color: #dc1010;
}
::v-deep .el-tabs__nav {
  height: 100px;
  justify-content: space-between;
}

::v-deep .el-pagination.is-background .el-pager li.is-active {
  background-color: #dc1010; // 进行修改选中项背景和字体
}
::v-deep .el-pager li:hover {
  color: #dc1010;
}

.mcontent {
  width: 85vw;
  height: 24vw;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  margin-bottom: 2.6667vw;
  margin-top: 2.6667vw;
  .mcontent-img {
    width: 40vw;
    height: 100%;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mcontent-intro {
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 4.8vw;
    box-sizing: border-box;

    .mcontent-intro-time {
      font-size: 3.7333vw;
      font-weight: 500;
      color: #999999;
    }
    .mcontent-intro-title {
      font-size: 3.7333vw;
      font-weight: 500;
      color: #333333;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
</style>