import serviceAxios from "../index";

// 首页轮播图
export const getCarouselMap = (params) => {
    return serviceAxios({
        url: "/sapi/index/1",
        method: "get",
        params,
    });
};

// 首页行业新闻
export const getIndustryNews = (params) => {
    return serviceAxios({
        url: "/sapi/index/6",
        method: "get",
        params,
    });
};

// 首页行业新闻内容
export const getIndustryNewsContent = (params) => {
    return serviceAxios({
        url: "/sapi/index/3",
        method: "get",
        params,
    });
};

// 首页项目业务内容
export const getProjectBusiness = (params) => {
    return serviceAxios({
        url: "/sapi/index/4",
        method: "get",
        params,
    });
};

// 首页合作伙伴内容
export const getBusinessPartner = (params) => {
    return serviceAxios({
        url: "/sapi/index/5",
        method: "get",
        params,
    });
};

// 关于我们内容
export const getOfficeEnvironment = (params) => {
    return serviceAxios({
        url: "/sapi/useMe/3",
        method: "get",
        params,
    });
};

// 关于我们办公环境
export const getAboutUs = (params) => {
    return serviceAxios({
        url: "/sapi/useMe/4",
        method: "get",
        params,
    });
};


// 关于我们头图
export const getHeadView = (id) => {
    return serviceAxios({
        url: "/sapi/page/1/" + id,
        method: "get",
    });
};

// 公司新闻内容
export const getCompanyNews = (params) => {
    return serviceAxios({
        url: "/sapi/wx/news",
        method: "get",
        params,
    });
};

// 行业新闻内容
export const getIndustryNew = (params) => {
    return serviceAxios({
        url: "/sapi/index/3",
        method: "get",
        params,
    });
};

export const getMonthlyBriefing = (params) => {
    return serviceAxios({
        url: "/sapi/adver/5",
        method: "get",
        params,
    });
};

// 行业新闻内容
export const getNewsDetails = (id) => {
    return serviceAxios({
        url: "/sapi/advertising/2/" + id,
        method: "get",
    });
};

// 广告中心内容
export const getAdvertisingCenter = (params) => {
    return serviceAxios({
        url: "sapi/advertising/1",
        method: "get",
        params,
    });
};

// 加入我们职业规划内容
export const getCareerPlanning = () => {
    return serviceAxios({
        url: "/sapi/joinus/1",
        method: "get",
    });
};

// 加入我们职业规划内容
export const getSubsidiaryCompany = () => {
    return serviceAxios({
        url: "/sapi/contact/1",
        method: "get",
    });
};

// 加入我们职业规划内容
export const interactiveMessage = (data) => {
    return serviceAxios({
        url: "/sapi/contact/1",
        method: "POST",
        data
    });
};

// 线下推广地区
export const getOfflinePromotion = () => {
    return serviceAxios({
        url: "/sapi/pservice/4/1",
        method: "GET",
    });
};

// 线下推广地区内容
export const getRegionalContent = (local) => {
    return serviceAxios({
        url: "/sapi/pservice/2/1/" + local,
        method: "GET",
    });
};

// 线上项目名称
export const getOnlineProjectName = () => {
    return serviceAxios({
        url: "/sapi/pservice/4/2",
        method: "GET",
    });
};

// 线上推广内容
export const getOnlineProjectContent = (local) => {
    return serviceAxios({
        url: "/sapi/pservice/2/2/" + local,
        method: "GET",
    });
};

// 获取年份
export const getYear = () => {
    return serviceAxios({
        url: "/sapi/useMe/1",
        method: "GET",
    });
};

// 通过年份获取月
export const getYearToMonth = (year) => {
    return serviceAxios({
        url: "/sapi/useMe/5/" + year,
        method: "GET",
    });
};

// 获取发展史详细信息
export const getHistoryDetails = (yearM) => {
    return serviceAxios({
        url: "/sapi/useMe/2/" + yearM,
        method: "GET",
    });
};