<template>
  <div>
    <div v-if="isMobile" class="home">
      <div class="wheelSeedingPicture">
        <div class="block text-center">
          <el-carousel height="500px">
            <el-carousel-item v-for="item in carouseData" :key="item">
              <img :src="item.url" style="width: 100%; height: 100%" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div style="margin-top: 100px" class="companyIntroduction">
        <!-- <h1 class="name">新闻动态</h1> -->
        <div class="content">
          <div class="content-img">
            <img v-if="companyIntroductionImg" :src="companyIntroductionImg" alt="" />
          </div>
          <div class="content-right">
            <div class="content-title">公司新闻</div>
            <div class="underline"></div>
            <div class="newsContents">
              <div
                class="newsContent"
                v-for="(item, index) of CompanyNewsContent"
                :key="index"
                @click="goContent(item)"
              >
                <div class="newsContent-title">
                  <img
                    style="width: 14px; height: 14px; margin-right: 15px"
                    src="../assets/before.png"
                    alt=""
                  />
                  <span
                    style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.title }}
                  </span>
                </div>

                <div class="newsContent-time">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="industryNews">
        <h1 class="name">行业动态</h1>
        <div class="underline"></div>
        <div class="content">
          <div class="content-img">
            <img
              v-if="industryNewsImg"
              style="border-radius: 0px 0px 0px 10px"
              class="firstChoice"
              :src="industryNewsImg"
              alt=""
            />
            <div class="spareTire" v-if="spareTireShow">
              <img src="../assets/masking.png" alt="" />
              <div class="spareTire-title">
                <div class="spareTire-title-title">
                  {{ spareTireTitle.title }}
                </div>
                <div class="spareTire-title-underline"></div>
                <div class="spareTire-title-content">
                  {{ spareTireTitle.value }}
                </div>
              </div>
            </div>
          </div>
          <div class="content-right">
            <div
              class="content-right-concrete"
              v-for="(item, index) in industryNewsContent"
              :key="index"
              @mouseenter="enters(item)"
              @mouseleave="leaver(item)"
              @click="goSpecificNews(item)"
            >
              <div class="content-right-concrete-time">{{ item.sendTime }}</div>
              <div class="content-right-concrete-title">
                {{ item.title }}
              </div>
              <div class="content-right-concrete-content">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="industryNews-seeMore" @click="goNews">查看更多</div>
      </div>
      <!-- <div class="advertisingCenter">
      <h1 class="name">项目业务</h1>
      <div class="advertisingCenter-b">
        <div class="advertisingCenter-content">
          <div class="advertisingCenter-content-title">
            {{ projectBusiness.name }}
          </div>
          <div class="advertisingCenter-content-underline"></div>
          <div class="advertisingCenter-content-content">
            {{ projectBusiness.commits }}
          </div>
          <div
            class="advertisingCenter-content-seeMore"
            @click="goProjectBusiness"
          >
            查看更多
          </div>
        </div>
        <div class="advertisingCenter-img">
          <img :src="projectBusinessImg" alt="" />
        </div>
      </div>
    </div> -->
      <!-- <div class="businessPartner">
      <h1 class="name">合作伙伴</h1>

      <vue3-seamless-scroll
      copyNum="0"
        singleLine="true"
        hover="true"
        direction="left"
        :list="BusinessPartnerImg"
        class="businessPartnerContent"
      >
      
        <div
          class="businessPartnerName"
          v-for="(item, index) in BusinessPartnerImg"
          :key="index"
          
        >
          <img :src="item.url" alt="" />
        </div>
        
      </vue3-seamless-scroll>
     
    </div> -->

      <!-- 测试 -->
    </div>
    <div v-else>
      <div class="mswiper">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in carouseData" :key="item">
            <img :src="item.url" alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="mCompanyNews">
        <div class="mCompanyNews-title">公司新闻</div>
        <div class="mCompanyNews-img">
          <img :src="companyIntroductionImg" alt="" />
        </div>
        <div class="mCompanyNews-content">
          <div
            class="mCompanyNews-content-1"
            v-for="(item, index) of mCompanyNewsContent"
            :key="index"
            @click="goContent(item)"
          >
            <div class="mCompanyNews-content-1-title">
              <img
                style="
                  width: 3.7333vw;
                  height: 3.7333vw;
                  margin-right: 2.6667vw;
                "
                src="../assets/before.png"
                alt=""
              />
              <span
                style="
                  display: inline-block;
                  width: 80%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ item.title }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="industryDynamics">
        <div class="industryDynamics-title">行业动态</div>
        <div class="industryDynamics-img">
          <img :src="industryNewsImg" alt="" />
        </div>
        <div class="industryDynamics-content">
          <div
            class="industryDynamics-content-1"
            v-for="(item, index) in industryNewsContent"
            :key="index"
            @click="goSpecificNews(item)"
          >
            <div class="industryDynamics-content-1-title">{{ item.title }}</div>
            <div class="industryDynamics-content-1-content">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mseeMore" @click="goNews">查看更多</div> -->
    </div>
  </div>
</template>

<script>
import {
  getCarouselMap,
  getIndustryNews,
  getIndustryNewsContent,
  getProjectBusiness,
  getBusinessPartner,
  getCompanyNews,
} from "../http/api/api";
import serverConfig from "../http/config/index";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
  name: "HomeView",
  components: {
    Vue3SeamlessScroll,
  },
  data() {
    return {
      carouseData: [
        
      ],
      data: {
        page: 1,
        limit: 6,
      },
      CompanyNewsContent: [],
      mCompanyNewsContent: [],
      companyIntroductionImg: null,
      industryNewsImg: null,
      spareTireShow: false,
      industryNewsContent: [],
      spareTireTitle: null,
      projectBusiness: {},
      projectBusinessImg: null,
      BusinessPartnerImg: [],
      isMobile: false,
    };
  },
  created() {
    this.getIndustryNews();
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  mounted() {
    this.getCarouselMap();
   
    this.getProjectBusiness();
    this.getBusinessPartner();
    this.getCompanyNews();
    this.getIndustryNewsContent();
  },
  methods: {
    goContent(item) {
      if (item.remark) {
        window.open(item.remark, "_blank");
      } else {
        this.$router.push({
          path: "/announcementDetails",
          query: { id: item.id },
        });
      }
    },
    goSpecificNews(item) {
      this.$router.push({
        path: "/announcementDetails",
        query: { id: item.id },
      });
    },
    goProjectBusiness() {
      this.$router.push({ path: "/projectBusiness" });
    },
    goNews() {
      this.$router.push({ path: "/newsTrends" });
    },
    enters(item) {
      this.spareTireShow = true;
      this.spareTireTitle = item;
      // console.log(this.spareTireTitle);
    },
    leaver(item) {
      this.spareTireShow = false;
    },
    getCarouselMap() {
      getCarouselMap().then((res) => {
        let data = res.data.records.map((item) => {
          return { url: serverConfig.baseURL + item.imgUrl };
        });
        this.carouseData = data;
      });
    },
    getCompanyNews() {
      let data = this.data;
      getCompanyNews(data).then((res) => {
        this.CompanyNewsContent = res.rows;
        this.mCompanyNewsContent = res.rows.slice(0, 6);
        if(this.CompanyNewsContent[0].img !== null) {
          if (this.CompanyNewsContent[0].img.indexOf("https") == -1) {
            this.companyIntroductionImg = serverConfig.baseURL + this.CompanyNewsContent[0].img
          } else {
            this.companyIntroductionImg = this.CompanyNewsContent[0].img
          }
          
        }
   
      });
    },
    getIndustryNews() {
      getIndustryNews().then((res) => {
        res.rows.forEach((item) => {
          if (item.type == 18) {
            this.companyIntroductionImg = serverConfig.baseURL + item.imgUrl;
          } else if (item.type == 19) {
            this.industryNewsImg = serverConfig.baseURL + item.imgUrl;
          }
        });
        console.log(res);
      });
    },
    getIndustryNewsContent() {
      getIndustryNewsContent().then((res) => {
        this.industryNewsContent = res.rows.slice(0, 4);
        console.log(123,this.industryNewsContent);
        if(this.industryNewsContent[0].img !== null) {
          this.industryNewsImg = serverConfig.baseURL + this.industryNewsContent[0].img
        }
      });
    },

    getProjectBusiness() {
      getProjectBusiness().then((res) => {
        console.log(999, res);
        res.data.forEach((item) => {
          this.projectBusinessImg = serverConfig.baseURL + item.imgUrl;
        });
        this.projectBusiness = res.data[0];
      });
    },
    getBusinessPartner() {
      getBusinessPartner().then((res) => {
        let data = res.rows.map((item) => {
          return { url: serverConfig.baseURL + item.imgUrl };
        });
        this.BusinessPartnerImg = data;
        console.log(this.BusinessPartnerImg, 777);
      });
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 100px;
}
.wheelSeedingPicture {
  margin-bottom: 79px;
}
.companyIntroduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 90px;
  .name {
    font-size: 36px;
    font-weight: 800;
    color: #dc1010;
    letter-spacing: 8px;
    margin-bottom: 40px;
  }
  .content {
    display: flex;
    align-items: center;
    .content-img {
      width: 580px;
      height: 400px;
      margin-right: 29px;
      // background-color: red;
      border-radius: 0px 20px 0px 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0px 20px 0px 20px;
      }
    }
    .content-right {
      width: 580px;
      height: 400px;
      .content-title {
        font-size: 28px;
        font-weight: 500;
        color: #262626;
        margin-left: 15px;
      }
      .underline {
        width: 100%;
        height: 1px;
        background: #bbbbbb;
        margin-top: 6px;
        margin-bottom: 25px;
      }

      .newsContents {
        height: 82%;
        overflow: hidden;
        .newsContent {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          cursor: pointer;
          .newsContent-title {
            width: 330px;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            margin-left: 10px;
          }
          .newsContent-time {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
        }
        .newsContent:hover {
          .newsContent-title {
            color: #dc1010;
            font-size: 23px;
            line-height: 26px;
          }
          .newsContent-time {
            color: #dc1010;
            font-size: 23px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
.industryNews {
  margin-top: 100px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .name {
    font-size: 28px;
    font-weight: 500;
    color: #262626;
    margin-bottom: 6px;
    margin-left: 15px;
  }
  .underline {
    width: 1197px;
    height: 1px;
    background: #dddddd;
  }
  .content {
    width: 100%;
    height: 500px;
    margin-top: 30px;
    display: flex;
    .content-img {
      width: 840px;
      height: 500px;

      position: relative;
      .firstChoice {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .spareTire {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 999;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .spareTire-title {
          position: absolute;
          bottom: 0;
          width: 90%;
          left: 50%;
          transform: translate(-50%, 0);
          padding-bottom: 30px;
          box-sizing: border-box;
          .spareTire-title-title {
            font-size: 30px;
            font-weight: 500;
            color: #ffffff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .spareTire-title-underline {
            width: 94px;
            height: 4px;
            background: #ffffff;
            margin: 15px 0;
          }
          .spareTire-title-content {
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            color: #f3f2f2;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }
      // background: url(../assets/industryNewsPicture.png) no-repeat 0 0;
      // /* 图片可以保有其原有的尺寸，或者拉伸到新的尺寸，或者在保持其原有比例的同时缩放到元素的可用空间的尺寸。 */
      // background-size: cover;
    }
    .content-right {
      width: 362px;
      display: flex;
      flex-direction: column;
      .content-right-concrete {
        height: 25%;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        border: 1px solid #efefef;
        cursor: pointer;
        .content-right-concrete-time {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          margin: 15px 0px 5px 0px;
        }
        .content-right-concrete-title {
          width: 80%;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 10px;
        }
        .content-right-concrete-content {
          width: 90%;
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
      .content-right-concrete:nth-child(-n + 3) {
        border-bottom: 0;
      }
      .content-right-concrete:hover {
        background-color: rgba(220, 16, 16, 0.1);
        border-right: 7px solid #dc1010;
      }
    }
  }
  .industryNews-seeMore {
    width: 201px;
    height: 44px;
    border: 1px solid #dc1010;
    border-radius: 22px;
    font-size: 18px;
    font-weight: 400;
    color: #dc1010;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
    letter-spacing: 5px;
    margin: 0 auto;
    margin-top: 83px;
  }
}

.advertisingCenter {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    font-size: 36px;
    font-weight: 800;
    color: #dc1010;
    letter-spacing: 8px;
    margin: 0;
    margin-bottom: 65px;
  }
  .advertisingCenter-b {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    position: relative;
    .advertisingCenter-content {
      width: 612px;
      height: 372px;
      background-color: rgba(255, 255, 255);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 5px;
      box-shadow: -1px 1px 10px 1px #e8e8e8;
      display: flex;
      flex-direction: column;
      align-items: center;
      .advertisingCenter-content-title {
        font-size: 39px;
        font-weight: bold;
        color: #333333;
        margin: 40px 0px 23px 0px;
      }

      .advertisingCenter-content-underline {
        width: 94px;
        height: 3px;
        background: #333333;
      }

      .advertisingCenter-content-content {
        width: 80%;
        font-size: 16px;
        font-weight: 400;
        margin: 35px 0px 30px 0px;
        color: #333333;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .advertisingCenter-content-seeMore {
        width: 201px;
        height: 44px;
        background-color: #dc1010;
        border-radius: 22px;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        line-height: 44px;
        text-align: center;
        cursor: pointer;
        letter-spacing: 5px;
        margin: 0 auto;
      }
    }
    .advertisingCenter-img {
      width: 760px;
      height: 530px;
      background-color: black;
      border-radius: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }
}
.businessPartner {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .name {
    font-size: 36px;
    font-weight: 800;
    color: #dc1010;
    letter-spacing: 8px;
    margin: 0;
    margin-bottom: 40px;
  }
  .businessPartnerContent {
    width: 100%;
    // display: flex;

    // justify-content: space-between;
    overflow-x: hidden;
    .businessPartnerName {
      width: 270px;
      height: 110px;
      border-radius: 5px;
      box-shadow: -1px 1px 10px 1px #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      margin-left: 5px;
      margin-top: 15px;
      margin-bottom: 5px;
      transition: all 0.5s;
      img {
        width: 80%;
      }
    }
    .businessPartnerName:hover {
      transform: translateY(-10px);
    }
  }
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 53.3333vw;
  img {
    width: 100%;
    height: 100%;
  }
}

.mCompanyNews {
  margin-top: 5.3333vw;
  .mCompanyNews-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #262626;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
  .mCompanyNews-img {
    width: 85vw;
    height: 55vw;
    border-radius: 0px 20px 0px 20px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0px 20px 0px 20px;
    }
  }

  .mCompanyNews-content {
    width: 90vw;
    margin: 0 auto;

    .mCompanyNews-content-1 {
      margin-left: 3vw;
      .mCompanyNews-content-1-title {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 4.2667vw;
        margin-left: 1.3333vw;
        margin: 2.6667vw 0px;
      }
    }

  }
}

.industryDynamics {
  margin-top: 5.3333vw;
  .industryDynamics-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #262626;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
  .industryDynamics-img {
    width: 85vw;
    height: 53vw;
    border-radius: 0px 20px 0px 20px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0px 20px 0px 20px;
    }
  }
  .industryDynamics-content {
    width: 85vw;
    margin: 0 auto;
    margin-top: 2.6667vw;
    .industryDynamics-content-1 {
      padding: 0.8vw 4vw;
      box-shadow: -1px 1px 10px 1px #efefef;
      margin-top: 2.6667vw;
      &.industryDynamics-content-1:nth-child(-n + 3) {
        border-bottom: 0px;
      }
      .industryDynamics-content-1-title {
        width: 90%;
        font-size: 4.8vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .industryDynamics-content-1-content {
        width: 95%;
        font-size: 3.2vw;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        color: #999999;
      }
    }
  }
}

.mseeMore {
  width: 40vw;
  height: 9.3333vw;
  border: 1px solid #dc1010;
  border-radius: 5.8667vw;
  font-size: 4.8vw;
  font-weight: 400;
  color: #dc1010;
  line-height: 9.3333vw;
  text-align: center;
  margin: 0 auto;
  margin-top: 5.3333vw;
}
</style>